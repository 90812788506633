<template>
  <b-card>
    <div class="fama-table">
      <div v-if="dataLoading" class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div v-else-if="error.length !== 0" class="m-auto">
        {{ error }}
      </div>
      <div v-else-if="error.length === 0 && can_list" style="width: 100%">
        <div class="row">
          <DxButton
              id="reset"
              :text="$t('pivot.resetTheState')"
              @click="() => resetState()"
          />

          <DxButton
              id="saveState"
              :text="$t('pivot.saveReportState')"
              @click="() => fakeSaveState()"
          />
          <DxButton
              id="saveState"
              :text="$t('pivot.loadReportState')"
              @click="() => fakeloadState()"
          />

          <DxButton :text="$t('pivot.expandAll')" @click="expandAll"/>
          <DxButton :text="$t('pivot.collapseAll')" @click="collapseAll"/>
          <DxButton text="Title" @click="changeHeader"/>
          <div class="col-md-1  dx-field">
            <DxSelectBox
                :items="dxLegendDatas"
                display-expr="name"
                value-expr="id"
                @value-changed="onLegendChange"
            />
          </div>
          <div class="col-md-1  dx-field">
            <DxSelectBox
                :value="type"
                :data-source="types"
                @value-changed="onChartTypeChange"
            />
          </div>
          <div class="col-md-1  dx-field">
            <DxNumberBox
                :value="chartSize"
                @value-changed="chartSizeChange"

            />
          </div>
        </div>
        <div class="long-title">
          <h3>{{ pivotHead }}</h3>
        </div>
        <DxChart ref="chart"
                 @point-click="onPointClick"
                 @legend-click="onLegendClick"
        >
          <DxSize :height="chartSize"/>
          <DxExport :enabled="true"/>
          <DxTooltip
              :enabled="true"
              :customize-tooltip="customizeTooltipKapo"
          />
          <DxAdaptiveLayout :width="450"/>
          <DxCommonSeriesSettings
              :type="type"/>
          <DxLegend
              :vertical-alignment="dxLegendData.v"
              :horizontal-alignment="dxLegendData.h"
              :visible="dxLegendData.vis"
          />
        </DxChart>

        <DxPivotGrid
            id="pivotGrid"
            ref="grid"
            :data-source="dataSource"
            :allow-sorting-by-summary="true"
            :allow-filtering="true"
            :show-borders="true"
            :show-column-grand-totals="showColumnGrandTotals"
            :show-row-grand-totals="showRowGrandTotals"
            :show-row-totals="showRowTotals"
            :show-column-totals="showColumnTotals"
            :column-auto-width="true"
            :show-totals-prior="showTotalsPrior"
            :data-field-area="dataFieldArea"
            :allow-expand-all="true"
            :row-header-layout="rowHeaderLayout"
            :word-wrap-enabled="false"
            :on-context-menu-preparing="onContextMenuPreparing"
            @exporting="onExporting"
            @cell-prepared="onCellPrepared"
        >
          <DxFieldChooser :enabled="true" :height="400" :allowSearch="true" class="chooser"/>
          <DxExport :enabled="true"/>
          <DxFieldPanel
              :show-column-fields="showColumnFields"
              :show-data-fields="showDataFields"
              :show-filter-fields="showFilterFields"
              :show-row-fields="showRowFields"
              :allow-field-dragging="true"
              :visible="true"
          />
          <DxStateStoring
              :enabled="true"
              type="custom"
              :custom-load="loadState"
              :custom-save="saveState"
          />

          <!--          <DxCommonSeriesSettings argument-field="bar"/>-->
          <DxSeries
              name="Cumulative percentage"
              value-field="cumulativePercentage"
              axis="percentage"
              type="spline"
              color="#6b71c3"
          />


        </DxPivotGrid>

        <div class="options">
          <div class="caption">{{ $t('pivot.options') }}</div>

          <div class="option">
            <DxCheckBox
                id="show-data-fields"
                :value="showColumnFields"
                :on-value-changed="OnShowColumnFieldsChanged"
                text="Show Data Fields"
            />
          </div>
          {{ ' ' }}
          <div class="option">
            <DxCheckBox
                id="show-row-fields"
                :value="showDataFields"
                :on-value-changed="OnShowDataFieldsChanged"
                text="Show Row Fields"
            />
          </div>
          {{ ' ' }}
          <div class="option">
            <DxCheckBox
                id="show-column-fields"
                :value="showFilterFields"
                :on-value-changed="OnShowFilterFieldsChanged"
                text="Show Column Fields"
            />
          </div>
          {{ ' ' }}
          <div class="option">
            <DxCheckBox
                id="show-filter-fields"
                :value="showRowFields"
                :on-value-changed="OnShowRowFieldsChanged"
                text="Show Filter Fields"
            />
          </div>
          {{ ' ' }}
          <div class="option">
            <DxCheckBox
                id="show-totals-prior"
                :value="false"
                :on-value-changed="onShowRowTotals"
                :text="$t('pivot.showRowTotals')"
            />
          </div>
          <div class="option">
            <DxCheckBox
                id="show-totals-prior"
                :value="false"
                :on-value-changed="onShowRowGrandTotals"
                :text="$t('pivot.showRowGrandTotals')"
            />
          </div>
          <div class="option">
            <DxCheckBox
                id="show-totals-prior"
                :value="false"
                :on-value-changed="onShowColumnGrandTotals"
                :text="$t('pivot.showColumnGrandTotals')"
            />
          </div>

          <div class="option">
            <DxCheckBox
                id="show-totals-prior"
                :value="false"
                :on-value-changed="onShowTotalColumns"
                :text="$t('pivot.showColumnTotals')"
            />
          </div>
          <div class="option">
            <DxCheckBox
                id="show-totals-prior"
                :value="false"
                :on-value-changed="onShowTotalsPriorChanged"
                :text="$t('pivot.showTotalsPrior')"
            />
          </div>
          <div class="option">
            <DxCheckBox
                id="data-field-area"
                :value="false"
                :on-value-changed="onDataFieldAreaChanged"
                :text="$t('pivot.dataFieldHeadersInRows')"
            />
          </div>
          <div class="option">
            <DxCheckBox
                id="row-header-layout"
                :value="false"
                :on-value-changed="onRowHeaderLayoutChanged"
                :text="$t('pivot.treeRowHeaderLayout')"
            />
          </div>
        </div>
      </div>
      <h1 v-else>{{ $t("noPermission") }}</h1>
    </div>

  </b-card>
</template>

<script>
import {DxNumberBox} from 'devextreme-vue/number-box';
import {
  DxChart,
  DxAdaptiveLayout,
  DxCommonSeriesSettings,
  DxSize,
  DxTooltip,
  DxLegend,
  DxSeries
} from 'devextreme-vue/chart';
import {DxSelectBox} from 'devextreme-vue/select-box';
import {
  DxPivotGrid,
  DxFieldChooser,
  DxStateStoring,
  DxExport,
  DxFieldPanel
} from 'devextreme-vue/pivot-grid';

import axios from '@axios';
import {BCard, BDropdown, BDropdownItem} from 'bootstrap-vue';
import DxButton from 'devextreme-vue/button';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import DxCheckBox from 'devextreme-vue/check-box';

import {Workbook} from 'exceljs';
import saveAs from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'.
import {exportPivotGrid} from 'devextreme/excel_exporter';
import {pivotFakeData} from "@/views/reports/pivotFakeData";
import {currencyFormatter} from "@/auth/utils";

/**
 *
 * activityName: "Dolgu Bölüm 2"
 notes: "asdasdads"
 quantity: 1212
 shift: "G"
 wbsName: "Dolgu"
 work_date: "2021-07-01"
 */

function download(strData, strFileName, strMimeType) {
  var D = document,
      A = arguments,
      a = D.createElement('a'),
      d = A[0],
      n = A[1],
      t = A[2] || 'text/html';

  //build download link:
  a.href = 'data:' + strMimeType + 'charset=utf-8,' + encodeURIComponent(strData);

  if (window.MSBlobBuilder) {
    // IE10
    var bb = new MSBlobBuilder();
    bb.append(strData);
    return navigator.msSaveBlob(bb, strFileName);
  } /* end if(window.MSBlobBuilder) */

  if ('download' in a) {
    //FF20, CH19
    a.setAttribute('download', n);
    a.innerHTML = 'downloading...';
    D.body.appendChild(a);
    setTimeout(function () {
      var e = D.createEvent('MouseEvents');
      e.initMouseEvent(
          'click',
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
      );
      a.dispatchEvent(e);
      D.body.removeChild(a);
    }, 66);
    return true;
  }
}

export default {
  components: {
    BCard,
    DxChart,
    DxAdaptiveLayout,
    DxCommonSeriesSettings,
    DxSize,
    DxTooltip,
    DxLegend,
    DxPivotGrid,
    DxButton,
    DxStateStoring,
    DxFieldChooser,
    DxCheckBox,
    DxExport,
    DxSeries,
    DxFieldPanel,
    DxSelectBox,
    DxNumberBox
  },
  data() {
    return {
      chartSize: 300,
      types: ["bar", "stackedbar", "stackedbar", "line", "stackedline", "fullstackedline", "line", "pie", "scatter"],
      type: 'bar',
      dxLegendDatas: [
        {
          id: 1,
          name: 'Right',
          v: 'center',
          h: 'right',
          vis: true
        },
        {
          id: 2,
          name: 'Left',
          v: 'center',
          h: 'left',
          vis: true
        },
        {
          id: 4,
          name: 'Bottom',
          v: 'bottom',
          h: 'center',
          vis: true
        },
        {
          id: 5,
          name: 'Hide',
          v: 'bottom',
          h: 'center',
          vis: true
        },
      ],
      dxLegendData: {
        id: 1,
        name: 'Right',
        v: 'center',
        h: 'right',
        vis: true
      },
      showColumnFields: false,
      showDataFields: false,
      showFilterFields: false,
      showRowFields: false,
      dataLoading: true,
      can_edit: false,
      can_list: false,
      can_create: false,
      can_delete: false,
      gropcalc: true,
      activityNetQts: [],
      error: '',
      showTotalsPrior: 'none',
      showColumnTotals: false,
      showRowGrandTotals: false,
      showColumnGrandTotals: false,
      showRowTotals: false,
      dataFieldArea: 'column',
      pivotHead: 'FamaMetric',
      rowHeaderLayout: 'none',
      allowCrossGroupCalculation: true,
      summaryDisplayModes: [
        {text: 'None', value: 'none'},
        {text: 'Absolute Variation', value: 'absoluteVariation'},
        {text: 'Percent Variation', value: 'percentVariation'},
        {text: 'Percent of Column Total', value: 'percentOfColumnTotal'},
        {text: 'Percent of Row Total', value: 'percentOfRowTotal'},
        {
          text: 'Percent of Column Grand Total',
          value: 'percentOfColumnGrandTotal'
        },
        {text: 'Percent of Row Grand Total', value: 'percentOfRowGrandTotal'},
        {text: 'Percent of Grand Total', value: 'percentOfGrandTotal'}
      ],
      selectedLanguage: {},
      filterRow: {
        visible: true
      },
      headerFilter: {
        visible: true
      },

      dataSource: {
        // kapoğlu
        fields: [
          {
            dataField: 'id',
          }, {
            caption: 'Chief Position',
            dataField: 'resposibleTop'
          },
          {
            caption: 'User Position',
            dataField: 'resposible'
          }, {
            caption: 'Notes',
            dataField: 'wNotes'
          },
          {
            caption: 'Wbs Mobile Name',
            dataField: 'wbsMobileName'
          },
          {
            caption: 'WBS Code',
            dataField: 'activityWbsCode'
          },
          {
            caption: 'ActCode',
            dataField: 'activityCode'
          },
          {
            caption: 'Act Name',
            dataField: 'activityName',
            width: 270
          },
          {
            caption: 'Act RO1 Description',
            dataField: 'activityRO1Descr',
            width: 270,
            area: 'row',
          }, {
            caption: 'Act RO2 Description',
            dataField: 'activityRO2Descr',
            width: 270,
          },
          {
            caption: 'Act RO3 Description',
            dataField: 'activityRO3Descr',
            width: 270,
          },
          {
            caption: 'Act Wastage',
            dataField: 'activityWwastage'
          },
          {
            caption: 'Act R.O. 1 UM',
            dataField: 'activityReportOrder1Unit'
          },
          {
            caption: 'Act R.O. 2 UM',
            dataField: 'activityReportOrder2Unit'
          },
          {
            caption: 'Act R.O. 3 UM',
            dataField: 'activityReportOrder3Unit'
          },
          {
            caption: 'Act UM',
            dataField: 'activityUM'
          },
          // {
          //   dataField: 'nnnetQty',
          //   caption: 'Net Qty (Sum)',
          //   dataType: 'number',
          //   summaryType: 'sum',
          //   format: 'fixedPoint'
          // },
          // {
          //   caption: 'NetQty R Total',
          //   dataField: 'nnnetQty',
          //   dataType: 'number',
          //   summaryType: 'sum',
          //   runningTotal: 'row',
          //   format: 'fixedPoint',
          //   allowCrossGroupCalculation: true
          // },
          {
            caption: 'Company',
            dataField: 'workFirmName'
          },
          {
            dataField: 'persNo',
            dataType: 'number',
            summaryType: 'sum',
            area: 'data',
            format: 'fixedPoint',
          },
          {
            caption: 'Pers Hour',
            dataField: 'persHr',
            summaryType: 'sum',
            format: 'fixedPoint'
          },
          {
            caption: 'RO1 Inc No',
            dataField: 'activityReportOrder1Coef',
            summaryType: 'sum',
            format: function (value) {
              return value.toFixed(2);
            },
            calculateSummaryValue: (summaryCell) => {
              let val = summaryCell.value('persNoRunning') / (summaryCell.value('runningKapo') * summaryCell.value('activityReportOrder1CoefRunning'));
              if (!val) return;
              if (summaryCell.children("row").length == 0) return val;
              let sum = 0;
              summaryCell.children("row").forEach(cell => {
                sum += cell.value();
              })
              return sum;
            }
          },
          {
            caption: 'RO1 Inc No2',
            dataField: 'activityReportOrder1Coef',
            summaryType: 'sum',
            format: function (value) {
              return value.toFixed(2);
            },
            calculateSummaryValue: (summaryCell) => {
              let val = 1 / (summaryCell.value('persNoRunning') / (summaryCell.value('runningKapo') * summaryCell.value('activityReportOrder1CoefRunning')));
              if (!val) return;
              if (summaryCell.children("row").length == 0) return val;
              let sum = 0;
              summaryCell.children("row").forEach(cell => {
                sum += cell.value();
              })
              return sum;
            }
          },
          {
            caption: 'RO2 Inc No',
            dataField: 'activityReportOrder2Coef',
            summaryType: 'sum',
            format: function (value) {
              return value.toFixed(2);
            },
            calculateSummaryValue: (summaryCell) => {
              let val = summaryCell.value('persNoRunning') / (summaryCell.value('runningKapo') * summaryCell.value('activityReportOrder2CoefRunning'));
              if (!val) return;
              if (summaryCell.children("row").length == 0) return val;
              let sum = 0;
              summaryCell.children("row").forEach(cell => {
                sum += cell.value();
              })
              return sum;
            }
          },
          {
            caption: 'RO3 Inc No',
            dataField: 'activityReportOrder3Coef',
            summaryType: 'sum',
            format: function (value) {
              return value.toFixed(2);
            },
            calculateSummaryValue: (summaryCell) => {
              let val = summaryCell.value('persNoRunning') / (summaryCell.value('runningKapo') * summaryCell.value('activityReportOrder3CoefRunning'));
              if (!val) return;
              if (summaryCell.children("row").length == 0) return val;
              let sum = 0;
              summaryCell.children("row").forEach(cell => {
                sum += cell.value();
              })
              return sum;
            }
          },
          {
            caption: 'Pers Inc Hr',
            dataField: 'perIncHr',
            summaryType: 'sum',
            format: function (value) {
              return value.toFixed(3);
            },

          },
          {
            caption: 'activityReportOrder1CoefRunning',
            dataField: 'activityReportOrder1Coef',
            dataType: 'number',
            summaryType: 'avg',
            runningTotal: 'row',
            allowCrossGroupCalculation: true,
            visible: false,
          },
          {
            caption: 'activityReportOrder2CoefRunning',
            dataField: 'activityReportOrder2Coef',
            dataType: 'number',
            summaryType: 'avg',
            runningTotal: 'row',
            allowCrossGroupCalculation: true,
            visible: false,
          },
          {
            caption: 'activityReportOrder3CoefRunning',
            dataField: 'activityReportOrder3Coef',
            dataType: 'number',
            summaryType: 'avg',
            runningTotal: 'row',
            allowCrossGroupCalculation: true,
            visible: false,
          },
          {
            caption: 'runningKapo',
            dataField: 'nnnetQty',
            dataType: 'number',
            summaryType: 'avg',
            runningTotal: 'column',
            allowCrossGroupCalculation: true,
            visible: false,
          },
          {
            caption: 'persNoRunning',
            dataField: 'persNo',
            dataType: 'number',
            summaryType: 'sum',
            runningTotal: 'row',
            allowCrossGroupCalculation: true,
            visible: false,
          },
          {
            caption: 'Pers Inc No',
            dataField: 'perIncNo',
            summaryType: 'sum',
            format: function (value) {
              return value.toFixed(3);
            },
            calculateSummaryValue: (summaryCell) => {
              let val = summaryCell.value('persNo') / summaryCell.value('runningKapo');
              if (!val) return;
              if (summaryCell.children("row").length == 0) return val;
              let sum = 0;
              summaryCell.children("row").forEach(cell => {
                sum += cell.value();
              })
              return sum;
            }
          },
          {
            caption: 'Pers Inc Hr2',
            dataField: 'perIncHr',
            summaryType: 'sum',
            calculateSummaryValue: function (summaryCell) {
              let sum = 0
              let sumColumn = 0
              summaryCell.children('row').forEach(s => {
                sum += s.value()
              })
              summaryCell.children('column').forEach(s => {
                sumColumn += s.value()
              })
              return sum > 0 ? sum : 1 / summaryCell.value()
              // Your code goes here
            }
          },

          {
            caption: 'Date',
            dataField: 'work_date',
            area: 'column',
            dataType: 'date'
          },
          {
            caption: 'Month No',
            selector: function (data) {
              let date = new Date(data.work_date);
              return date.getMonth();
            }
          },
          {
            caption: 'Week',
            area: 'column',
            selector: function (data) {
              let date = new Date(data.work_date);
              date.setHours(0, 0, 0, 0);
              date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
              var week1 = new Date(date.getFullYear(), 0, 4);
              return (
                  1 +
                  Math.round(
                      ((date.getTime() - week1.getTime()) / 86400000 -
                          3 +
                          ((week1.getDay() + 6) % 7)) /
                      7
                  )
              );
            }
          },

          {
            caption: 'Day',
            area: 'column',
            dataField: 'work_date',
            dataType: 'date',
            groupInterval: 'day'
          },
          {
            dataField: 'activityBoqCoef',
            visible: false
          },
          {
            dataField: 'machineHR',
            visible: false
          },
          {
            dataField: 'activityReportOrder2Coef',
            visible: false
          },
          {
            dataField: 'activityReportOrder3Coef',
            visible: false
          },
          {
            dataField: 'id',
            visible: false
          },
          {
            dataField: 'nnnetQty',
            visible: false
          }
        ],
        store: null
      },
      helperUser: [],
      helperMainFirm: [],
      helperSubFirm: [],
      helperPositions: [],
    };
  },
  async mounted() {
    await this.getLanguage();
    await this.getPageAuths();
    await this.getHelpers();

    await this.getWorkData();

  },
  methods: {
    async getHelpers() {
      this.error = '';
      const response = await axios.get('/pivotHelp');

      try {
        this.helperUser = response?.data?.users || [];
        this.helperMainFirm = response?.data?.firms || [];
        this.helperSubFirm = response?.data?.subFirms || [];
        this.helperPositions = response?.data?.positions || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    onPointClick({target: point}) {
      point.select();
    },

    onLegendClick({target: series}) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    customizeTooltipKapo(pointInfo) {
      const valueText = pointInfo.originalValue.toLocaleString();
      return {
        html: `${pointInfo.seriesName} | Total<div class='currency'>${valueText}</div>`,
      };
    },
    onLegendChange(e) {
      this.dxLegendData = this.dxLegendDatas.find(a => a.id == e.value)
    },
    onChartTypeChange(e) {
      this.type = e.value
      this.bindChart();
    },
    OnShowColumnFieldsChanged(e) {
      this.showColumnFields = e.value;
    },
    OnShowDataFieldsChanged(e) {
      this.showDataFields = e.value;
    },
    OnShowFilterFieldsChanged(e) {
      this.showFilterFields = e.value;
    },
    OnShowRowFieldsChanged(e) {
      this.showRowFields = e.value;
    },
    async submittersOneTop(submitters) {
      let q = await axios.post("/submittersOneTop", {submitters: submitters})
      return q?.data ?? []
    },
    async getPageAuths() {
      const pageAuths = await axios.post("/get-page-auth", {page: "Pivot Personnel"});
      this.can_edit = pageAuths.data.can_edit;
      this.can_list = pageAuths.data.can_list;
      this.can_delete = pageAuths.data.can_delete;
      this.can_create = pageAuths.data.can_create;
    },
    async getLanguage() {
      this.error = '';
      const response = await axios.get('/list-language');

      try {
        this.languages = response?.data?.result || [];
        const selected = this.languages.find(
            (x) => x?.code === localStorage.dbLanguage
        );
        this.selectedLanguage = selected;
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    onLanguageSelect(lang) {
      this.selectedLanguage = lang;
      localStorage.dbLanguage = lang.code;
      this.getWorkData();
    },
    expandAll() {
      const pivotGrid = this.$refs.grid.instance.getDataSource();

      var rowFields = pivotGrid.getAreaFields('row', true);
      rowFields.forEach((row) => {
        pivotGrid.expandAll(row.dataField);
      });

      var columnFields = pivotGrid.getAreaFields('column', true);
      columnFields.forEach((column) => {
        pivotGrid.expandAll(column.dataField);
      });
    },
    collapseAll() {
      const pivotGrid = this.$refs.grid.instance.getDataSource();

      var rowFields = pivotGrid.getAreaFields('row', true);
      rowFields.forEach((row) => {
        pivotGrid.collapseAll(row.dataField);
      });

      var columnFields = pivotGrid.getAreaFields('column', true);
      columnFields.forEach((column) => {
        pivotGrid.collapseAll(column.dataField);
      });
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Famametric Report');

      exportPivotGrid({
        component: e.component,
        worksheet,
        customizeCell: ({pivotCell, excelCell}) => {
          if (this.isDataCell(pivotCell) || this.isTotalCell(pivotCell)) {
            const appearance = this.getConditionalAppearance(pivotCell);
            Object.assign(excelCell, this.getExcelCellFormat(appearance));
          }
          const borderStyle = {style: 'thin', color: {argb: 'FF7E7E7E'}};

          excelCell.border = {
            bottom: borderStyle,
            left: borderStyle,
            right: borderStyle,
            top: borderStyle
          };
        }
      })
          .then(() => {
            // Header
            const headerRow = worksheet.getRow(2);
            headerRow.height = 30;

            const columnFromIndex = worksheet.views[0].xSplit + 1;
            const columnToIndex = columnFromIndex + 3;
            //worksheet.mergeCells(2, columnFromIndex, 2, columnToIndex);

            const headerCell = headerRow.getCell(columnFromIndex);
            headerCell.value = this.pivotHead;

            headerCell.font = {name: 'Segoe UI Light', size: 22, bold: true};
            headerCell.alignment = {
              horizontal: 'left',
              vertical: 'middle',
              wrapText: true
            };
          })
          .then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
              saveAs(
                  new Blob([buffer], {type: 'application/octet-stream'}),
                  'Famametric_Report.xlsx'
              );
            });
          });
      e.cancel = true;
    },
    formatSliderTooltip(value) {
      return (
          value + ' | ' + ((value / this.sliderMaxValue) * 100).toFixed(1) + '%'
      );
    },
    saveState(state) {
      localStorage.setItem(
          'dx-widget-gallery-pivotgrid-storing-perssonel',
          JSON.stringify(state)
      );
    },
    resetState() {
      localStorage.setItem('dx-widget-gallery-pivotgrid-storing-perssonel', '');
      localStorage.removeItem('pivotHead')
      location.reload();
    },
    fakeSaveState() {

      var x = localStorage.getItem('dx-widget-gallery-pivotgrid-storing-perssonel');
      let q = JSON.parse(x)
      q.pivotHead = this.pivotHead
      var resp = window.prompt('File Name:');
      if (resp != null) {
        download(JSON.stringify(q), resp + '.fama', 'text/html');
      }
    },
    changeHeader() {
      var resp = window.prompt('Header Title:');
      if (resp != null) {
        this.pivotHead = resp;
      }
    },
    loadState() {
      var s = localStorage.getItem('dx-widget-gallery-pivotgrid-storing-perssonel');
      if (s != null && s != '') {
        return JSON.parse(
            localStorage.getItem('dx-widget-gallery-pivotgrid-storing-perssonel')
        );
      }
    },
    onCellPrepared({cell, area, cellElement}) {
      cell.area = area;
      if (this.isDataCell(cell) || this.isTotalCell(cell)) {
        const appearance = this.getConditionalAppearance(cell);
        Object.assign(cellElement.style, this.getCssStyles(appearance));
      }
    },
    isDataCell(cell) {
      return (
          cell.area === 'data' && cell.rowType === 'D' && cell.columnType === 'D'
      );
    },
    isTotalCell(cell) {
      return (
          cell.type === 'T' ||
          cell.type === 'GT' ||
          cell.rowType === 'T' ||
          cell.rowType === 'GT' ||
          cell.columnType === 'T' ||
          cell.columnType === 'GT'
      );
    },
    fakeloadState() {
      var input = document.createElement('input');
      input.accept = '.fama';
      input.type = 'file';

      input.onchange = (e) => {
        var file = e.target.files[0];

        var reader = new FileReader();
        reader.readAsText(file, 'UTF-8');

        reader.onload = (readerEvent) => {
          var content = readerEvent.target.result;
          localStorage.setItem('dx-widget-gallery-pivotgrid-storing-perssonel', content);
          let q = JSON.parse(content)
          localStorage.setItem('pivotHead', q.pivotHead)
          location.reload();
        };
      };
      input.click();
    },
    onShowTotalsPriorChanged(data) {
      this.showTotalsPrior = data.value ? 'both' : 'none';
    },
    onShowTotalColumns(data) {
      this.showColumnTotals = data.value ? true : false;
    },
    onShowRowGrandTotals(data) {
      this.showRowGrandTotals = data.value ? true : false;
    },
    onShowColumnGrandTotals(data) {
      this.showColumnGrandTotals = data.value ? true : false;
    },
    onShowRowTotals(data) {
      this.showRowTotals = data.value ? true : false;
    },
    onDataFieldAreaChanged(data) {
      this.dataFieldArea = data.value ? 'row' : 'column';
    },

    onRowHeaderLayoutChanged(data) {
      this.rowHeaderLayout = data.value ? 'tree' : 'standard';
    },
    getExcelCellFormat({fill, font, bold}) {
      return {
        fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: fill}},
        font: {color: {argb: font}, bold}
      };
    },
    getCssStyles({fill, font, bold}) {
      return {
        'background-color': `#${fill}`,
        color: `#${font}`,
        'font-weight': bold ? 'bold' : undefined
      };
    },
    getConditionalAppearance(cell) {
      if (this.isTotalCell(cell)) {
        return {fill: 'F2F2F2', font: '3F3F3F', bold: true};
      }
      return {font: null, fill: null};
    },
    chartSizeChange(e) {
      this.chartSize = e.value
    },
    bindChart() {
      setTimeout(() => {
        const pivotGrid = this.$refs.grid.instance;
        const chart = this.$refs.chart.instance;
        pivotGrid.bindChart(chart, {
          dataFieldsDisplayMode: 'splitPanes',
          alternateDataFields: false
        });
      }, 1)

    },
    async getWorkData() {
      let p = localStorage.getItem('pivotHead')
      if (p) {
        this.pivotHead = p
      }
      // this.dataSource.store = pivotFakeData.result
      // this.dataLoading = false;
      // this.bindChart();
      // return

      this.dataLoading = true;

      this.error = '';
      // await axios
      //     .get('/list-work-by-pivot-try2');
      axios
          .get('/list-work-by-personnel')
          .then((response) => {
            if (response.status === 200) {

              let res = response?.data?.result || [];
              let firms = response?.data?.firms || [];
              let submitters = []
              let resData = res.map(e => {

                const submitter = e['submitter_id']
                let submitterCheck = submitters.find(sub => sub == submitter)
                if (!submitterCheck) submitters.push(submitter)
                const worksUserFirstname = e['user.firstname'] ? e['user.firstname'] : '';
                const worksUserLastname = e['user.lastname'] ? e['user.lastname'] : '';
                const enteredBy = `${worksUserFirstname} ${worksUserLastname}`;
                const worksFirmID = e.is_main_firm ? e.firm_id : e.sub_firm_id;
                let firmName = null;
                if (e.is_main_firm) {
                  let checkFirm = this.helperMainFirm.find(l => l.id == worksFirmID)
                  if (checkFirm) firmName = checkFirm.name
                } else {
                  let checkFirm = this.helperSubFirm.find(l => l.id == worksFirmID)
                  if (checkFirm) firmName = checkFirm.name
                }
                let responsibleCheck = this.helperUser.find(a => e.submitter_id == a.id)

                let submitResponsibleName = ""
                let submitResponsible = ""
                let submitResponsibleSelf = ""
                if (responsibleCheck) {
                  submitResponsibleName = responsibleCheck.firstname + " " + responsibleCheck.lastname
                  let positionCheck = this.helperPositions.find(a => a.id == responsibleCheck.position_id)
                  if (positionCheck) {
                    submitResponsibleSelf = positionCheck ? positionCheck.name : ''
                    submitResponsible = positionCheck ? positionCheck.chief_position_id : ''
                    let chiefPositionCheck = this.helperPositions.find(l => l.id == positionCheck.chief_position_id)
                    if (chiefPositionCheck) {
                      submitResponsible = chiefPositionCheck.name
                    }
                  }

                }
                // uğur
                const wbsMobileName = e['activity.wb.languages.wbs_translation.mobile_name'] ? e['activity.wb.languages.wbs_translation.mobile_name'] : '';
                const activityWbsCode = e['activity.wb.code'] ? e['activity.wb.code'] : '';
                const id = e['id'];
                const activityCode = e['activity.code'];
                const activityRO1Descr = e['activity.languages.activity_translation.report_order_1']
                    ? e['activity.languages.activity_translation.report_order_1']
                    : '';
                const activityRO2Descr = e['activity.languages.activity_translation.report_order_2']
                    ? e['activity.languages.activity_translation.report_order_2']
                    : '';
                const activityRO3Descr = (e['activity.languages.activity_translation.report_order_3']);
                const workFirmName = firmName;
                const {notes, quantity, shift, work_date} = e;
                const workPersonelID = e['work_personnels.personnel.languages.personnel_translation.personnel_id']
                    ? e['work_personnels.personnel.languages.personnel_translation.personnel_id']
                    : '';
                let perGrName = '';
                let persDescr = '';
                let persCode = '';
                let persGrCode = '';
                if (e['work_personnels.personnel_group_id']) {
                  perGrName = e['work_personnels.personnel_group.languages.personnel_group_translation.name']
                      ? e['work_personnels.personnel_group.languages.personnel_group_translation.name']
                      : '';
                  persDescr = '';

                  persGrCode = e['work_personnels.personnel_group.code'] ? e['work_personnels.personnel_group.code'] : '';

                  // work_personnels.personnel.personnel_group.languages.personnel_group_translation.name
                } else if (workPersonelID) {
                  perGrName = e['work_personnels.personnel.personnel_group.languages.personnel_group_translation.name']
                      ? e['work_personnels.personnel.personnel_group.languages.personnel_group_translation.name']
                      : '';
                  persDescr = e['work_personnels.personnel.languages.personnel_translation.name']
                      ? e['work_personnels.personnel.languages.personnel_translation.name']
                      : '';
                  persCode = e['work_personnels.personnel.code'] ? e['work_personnels.personnel.code'] : '';
                  persGrCode = e['work_personnels.personnel.personnel_group.code'] ? e['work_personnels.personnel.personnel_group.code'] : '';
                }
                const persNo = e['work_personnels.count'] ? e['work_personnels.count'] : 0;
                const workPersonelHour = e['work_personnels.hour'] ? e['work_personnels.hour'] : '';
                const activityWastage = e['activity.wastage'] ? e['activity.wastage'] : 0;
                const activityReportOrder1Coef = e['activity.report_order_1_coef'] ? e['activity.report_order_1_coef'] : '';
                const activityReportOrder1UnitID = e['activity.report_order_1_unit_id'] ? e['activity.report_order_1_unit_id'] : '';
                const activityReportOrder2Coef = e['activity.report_order_2_coef'] ? e['activity.report_order_2_coef'] : '';
                const activityReportOrder2UnitID = e['activity.report_order_2_unit_id'] ? e['activity.report_order_2_unit_id'] : '';
                const activityReportOrder3Coef = e['activity.report_order_3_coef'] ? e['activity.report_order_3_coef'] : '';
                const activityReportOrder3UnitID = e['activity.report_order_3_unit_id'] ? e['activity.report_order_3_unit_id'] : '';
                const activityName = e['activity.languages.activity_translation.name'] ? e['activity.languages.activity_translation.name'] : '';
                const activityReportOrder1Unit = e['activity.report_order_1_unit.symbol'] ? e['activity.report_order_1_unit.symbol'] : '';
                const activityReportOrder2Unit = e['activity.report_order_2_unit.symbol'] ? e['activity.report_order_2_unit.symbol'] : '';
                const activityReportOrder3Unit = e['activity.report_order_3_unit.symbol'] ? e['activity.report_order_3_unit.symbol'] : '';
                const activityWwastage = e['activity.wastage'];
                const wNotes = e['work_personnels.notes'];
                const resposibleTop = ""
                const resposible = ""
                const activityUM = e['activity.unitMain.symbol'];
                const nnnetQty = quantity * (1 - activityWastage);
                const persTotHr = persNo * workPersonelHour;
                const perIncHr = persTotHr / nnnetQty;
                const perIncNo = persNo / nnnetQty;

                const ro1Qty = nnnetQty * activityReportOrder1Coef;
                const ro2Qty = nnnetQty * activityReportOrder2Coef;
                const ro3Qty = nnnetQty * activityReportOrder3Coef;
                const check = this.activityNetQts.find(a => id == a.id)
                if (!check) this.activityNetQts.push({id, nnnetQty})
                return {
                  activityReportOrder1Coef,
                  activityReportOrder2Coef,
                  activityReportOrder3Coef,
                  id,
                  shift,
                  wbsMobileName,
                  activityCode,
                  activityName,
                  perGrName,
                  persCode,
                  persDescr,
                  persGrCode,
                  workPersonelHour,
                  nnnetQty,
                  persTotHr,
                  perIncHr,
                  perIncNo,
                  persNo,
                  work_date,
                  ro1Qty,
                  ro2Qty,
                  ro3Qty,
                  activityReportOrder1Unit,
                  activityReportOrder2Unit,
                  activityReportOrder3Unit,
                  activityUM,
                  activityWwastage,
                  workFirmName,
                  enteredBy,
                  activityRO1Descr,
                  activityRO2Descr,
                  activityRO3Descr,
                  activityWbsCode,
                  wNotes,
                  resposibleTop: submitResponsible,
                  resposible: submitResponsibleSelf,
                  submitter: submitResponsibleName
                }
              })
              this.submittersOneTop(submitters).then(submitterResult => {
                // resData.forEach(resD => {
                //   const submitCheck = submitterResult.find(subm => subm.userId == resD.submitter)
                //   if (submitCheck) {
                //     resD.resposibleTop = submitCheck.topPositionName
                //     resD.resposible = submitCheck.altPositionName
                //   }
                //
                // })
                this.dataSource.store = resData

                this.dataLoading = false;
                this.bindChart()
              })

            } else {
              this.error = response?.data?.message || 'Unexpected API error';
              this.dataLoading = false;
            }
          })
          .catch((e) => {
            this.error = e?.response?.data?.message || e?.message;
            this.dataLoading = false;
          });
    },
    showToast({variant = 'success', title, text, icon = 'XIcon'}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    },
    onContextMenuPreparing(e) {
      var dataSource = e.component.getDataSource(),
          sourceField = e.field;

      if (sourceField) {
        if (!sourceField.groupName || sourceField.groupIndex === 0) {
          e.items.push({
            text: 'Hide field',
            onItemClick: function () {
              var fieldIndex;
              if (sourceField.groupName) {
                fieldIndex = dataSource.getAreaFields(sourceField.area, true)[
                    sourceField.areaIndex
                    ].index;
              } else {
                fieldIndex = sourceField.index;
              }

              dataSource.field(fieldIndex, {
                area: null
              });
              dataSource.load();
            }
          });
        }

        if (sourceField.dataType === 'number') {
          var setSummaryType = function (args) {
                dataSource.field(sourceField.index, {
                  summaryType: args.itemData.value
                });

                dataSource.load();
              },
              menuItems = [];

          e.items.push({text: 'Summary Type', items: menuItems});

          ['Sum', 'Avg', 'Min', 'Max', 'Percent of Row Total'].forEach(
              (summaryType) => {
                var summaryTypeValue = summaryType.toLowerCase();

                menuItems.push({
                  text: summaryType,
                  value: summaryType.toLowerCase(),
                  onItemClick: setSummaryType,
                  selected: e.field.summaryType === summaryTypeValue
                });
              }
          );
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import '../tables/tables.scss';
</style>

<style scoped>
.long-title h3 {
  font-family: 'Segoe UI Light', 'Helvetica Neue Light', 'Segoe UI',
  'Helvetica Neue', 'Trebuchet MS', Verdana;
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.options {
  padding: 20px;
  margin-top: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option:last-child {
  margin-right: 0;
}

.option {
  width: 33%;
  display: inline-block;
  margin-top: 10px;
  margin-right: 4px;
}

.fama-table .dx-button {
  margin-bottom: 20px;
}


.fama-table .dx-button {
  margin-right: 10px;
}

/*DxFieldChooser{*/
/*   box-shadow: #1ab7ea!important;*/
/*}*/

</style>
